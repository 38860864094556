@import '../node_modules/frow/frow.sass';
@import '../node_modules/material-icons/iconfont/material-icons.css';
@import '../node_modules/tippy.js/dist/tippy.css';
@import '../node_modules/tippy.js/animations/scale-subtle.css';
@import '~ngx-toastr/toastr';
@import '~@ng-select/ng-select/themes/default.theme.css';

:root {
  --text-color: #1c1b1c;
  --text-color-rgb: 28, 27, 28;
  --background-color: #ffffff;
  --background-color-rgb: 255, 255, 255;
  --input-color: #f3f3f3;
  --input-color-rgb: 243, 243, 243;
  --main-color: #249bde;
  --main-color-rgb: 36, 155, 222;
  --warn-color: #de2455;
  --warn-color-rgb: 222, 36, 85;
  --success-color: #5ec18b;
  --success-color-rgb: 94, 193, 139;
  --attention-color: #fbcb0f;
  --attention-color-rgb: 251, 203, 15;
  --grey-color: #676767;
  --grey-color-rgb: 103, 103, 103;
  --grey-highlight-color: #f2f2f2;

  --area-bg: white;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  background: var(--background-color);
  overflow-x: hidden;

  &.dark {
    --background-color: #1c1b1c;
    --background-color-rgb: 28, 27, 28;
    --text-color: #ffffff;
    --text-color-rgb: 255, 255, 255;
    --input-color: #f3f3f3;
    --input-color-rgb: 243, 243, 243;
    --main-color: #249bde;
    --main-color-rgb: 36, 155, 222;
    --warn-color: #de2455;
    --warn-color-rgb: 222, 36, 85;
    --success-color: #5ec18b;
    --success-color-rgb: 94, 193, 139;
    --attention-color: #fbcb0f;
    --attention-color-rgb: 251, 203, 15;
    --grey-color: #676767;
    --grey-color-rgb: 103, 103, 103;
    --grey-highlight-color: #343434;

    --area-bg: #282728;
  }

  &.modal-open {
    overflow-y: hidden;
  }
}

html,
body {
  min-height: 100vh;
  color: var(--text-color);
  transition: color, background 0.1s ease-in-out;
  letter-spacing: 0.2px;
}
body > footer {
  margin-top: auto;
}

hr {
  border: none;
  border-top: 1px solid rgba(var(--text-color-rgb), 0.1);
}

.gradient-loading {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);

  &:before {
    backface-visibility: hidden;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: var(--background-color);
    background: linear-gradient(to right, transparent 8%, rgba(var(--text-color-rgb), 0.05) 38%, transparent 54%);
    background-size: 150% 150%;
    z-index: 1;
  }

  &:after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    background: var(--background-color);
    visibility: visible;
  }

  &.done {
    &:before {
      display: none;
    }

    &:after {
      transition: opacity 0.12s ease-in, visibility 0.12s linear 0.12s;
      background: var(--background-color);
      animation: unset;
      opacity: 0;
      visibility: hidden;
    }
  }
}

.gradient-loading-bg {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);

  &:before {
    backface-visibility: hidden;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: rgba(var(--text-color-rgb), 0.015);
    background: linear-gradient(to right, transparent 8%, rgba(var(--text-color-rgb), 0.05) 38%, transparent 54%);
    background-size: 150% 150%;
    z-index: 1;
  }

  &:after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    background: rgba(var(--text-color-rgb), 0.015);
  }

  &.done {
    &:before {
      display: none;
    }

    &:after {
      transition: opacity 0.12s ease-in;
      background: #f6f7f8;
      animation: unset;
      opacity: 0;
    }
  }
}

.font-normal {
  font-size: 1rem;
}

@keyframes placeHolderShimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}

input.ng-invalid:not(.ng-untouched),
textarea.ng-invalid:not(.ng-untouched) {
  border-color: var(--warn-color);
}

span.status-badge {
  padding: 5px 10px;
  border-radius: 2px;
  color: var(--background-color);

  &.finished {
    background: var(--main-color);
  }

  &.concluded {
    background: var(--text-color);
  }

  &.cancelled {
    background: var(--text-color);
  }

  &.active {
    background: var(--success-color);
  }

  &.upcoming {
    background: var(--main-color);
  }
}

.objkt-loader,
.objkt-loader:before,
.objkt-loader:after {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.objkt-loader {
  color: var(--text-color);
  font-size: 10px;
  margin: 10px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.objkt-loader:before,
.objkt-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.objkt-loader:before {
  left: -20px;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.objkt-loader:after {
  left: 20px;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

table {
  border-spacing: 0;
  border: 1px solid rgba(var(--text-color-rgb), 0.1);
  border-radius: 4px;
  th {
    text-align: left;
    font-size: 0.9rem;
    opacity: 0.7;
    padding: 10px 5px;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 0.1);
    background: rgba(var(--text-color-rgb), 0.04);

    &.hidden-xs {
      display: table-cell !important;

      @media (max-width: 767px) {
        display: none !important;
      }
    }
  }

  th,
  td {
    padding: 5px;

    &:first-child {
      padding-left: 1rem;
    }

    &:last-child {
      padding-right: 1rem;
    }
  }

  tr {
    &:hover {
      background-color: rgba(var(--text-color-rgb), 0.01);
    }
  }

  td {
    border-bottom: 1px solid rgba(var(--text-color-rgb), 0.04);
    vertical-align: middle;
    height: 80px;

    &.hidden-xs {
      display: table-cell !important;

      @media (max-width: 767px) {
        display: none !important;
      }
    }
  }
}

.sticky-notice {
  padding: 10px;
  background: #fdeaee;
  text-align: center;
  color: #1c1b1c !important;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  position: fixed;
  z-index: 10;
  white-space: nowrap;
  font-size: 0.8rem;

  a {
    color: #1c1b1c !important;
  }
}

a.button {
  border: 1px solid #4f4f51;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  outline: none;
  padding: 7px;
}

button,
a.button,
[type='button'],
[type='reset'],
[type='submit'] {
  background-color: var(--text-color);
  border-color: var(--text-color);
  color: var(--background-color) !important;
  transition: transform 0.1s, background 0.4s, border-color 0.4s, color 0.4s, box-shadow 0.4s, opacity 0.4s;

  &:disabled,
  &:disabled:hover {
    background: var(--text-color);
    border-color: var(--text-color);
    color: var(--background-color) !important;
    opacity: 0.4;
    cursor: not-allowed;
  }
}
body.dark {
  button,
  a.button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    background-color: var(--background-color);
    border-color: rbga(var(--text-color-rgb), 0.2);
    color: var(--text-color) !important;
    transition: transform 0.1s, background 0.4s, border-color 0.4s, color 0.4s, box-shadow 0.4s, opacity 0.4s;

    &:disabled,
    &:disabled:hover {
      background: var(--background-color);
      border-color: rbga(var(--text-color-rgb), 0.2);
      color: var(--text-color) !important;
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
button:hover,
button:focus,
button:active,
a.button:hover,
a.button:active,
a.button:focus,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  opacity: 0.8;
}

input:placeholder-shown,
input::placeholder,
textarea:placeholder-shown,
textarea::placeholder {
  color: rgba(var(--text-color-rgb), 0.2);
}
input,
select {
  border-bottom: 2px solid rgba(var(--text-color-rgb), 0.4);
  color: var(--text-color);
}

select option {
  color: black !important;
}

input:hover,
input:focus {
  border-color: rgba(var(--text-color-rgb), 0.5);
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
button {
  color: var(--text-color);
}

a:hover,
a:focus,
a:active {
  color: rgba(var(--text-color-rgb), 0.9);
}

.ngx-pagination a,
.ngx-pagination button {
  color: var(--text-color) !important;
}

label,
.label {
  color: rgba(var(--text-color-rgb), 0.5);
}

body.dark img.social-icon,
body.dark img.dark-toggle {
  filter: invert(100%);
}

.custom-select {
  position: relative;
  min-width: 120px;
  margin-bottom: 0;
  svg {
    position: absolute;
    right: 12px;
    top: calc(50% - 3px);
    width: 10px;
    height: 6px;
    stroke-width: 2px;
    stroke: #9098a9;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    pointer-events: none;
  }
  select {
    -webkit-appearance: none;
    padding: 7px 40px 7px 12px;
    height: 42px;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 5px;
    background: rgba(var(--text-color-rgb), 0.06);
    cursor: pointer;
    font-family: inherit;
    font-size: 0.8rem;
    transition: all 150ms ease;
    margin: 0;
    line-height: 1rem;

    &:required {
      &:invalid {
        color: var(--warn-color);
      }
    }
    option {
      color: var(--text-color);

      &.placeholder {
        color: rgba(var(--text-color-rgb), 0.25);
      }
    }
    option[value=''][disabled] {
      display: none;
    }
    &:focus {
      outline: none;
      border-color: rgba(var(--text-color-rgb), 0.08);
    }
    &:hover {
      & + svg {
        stroke: var(--main-color);
      }
    }
  }
}
.sprites {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

.toast-container {
  border: 1px solid rgba(var(--text-color-rgb), 0.08);
  background: rgba(var(--text-color-rgb), 0.06);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(10px);
  z-index: 99;

  &.toast-top-right {
    top: 85px;
    right: 15px;
    margin-left: 15px;
    border-radius: 4px;
  }

  &.toast-bottom-right {
    bottom: 15px;
    right: 15px;
    margin-left: 15px;
    border-radius: 4px;
  }

  .ngx-toastr {
    width: auto;
    margin: 15px;
    max-width: 450px;
    display: block;
    padding: 15px 55px 15px 15px;
    border-radius: 4px;
    border: 1px solid rgba(var(--text-color-rgb), 0.14);
    box-shadow: none;
    background: var(--area-bg);
    transition: box-shadow 0.12s ease-in;
    animation: newNotification 500ms cubic-bezier(0.34, 1.56, 0.64, 1) forwards;

    &:hover {
      box-shadow: 3px 3px 8px rgba(var(--text-color-rgb), 0.08), -3px -3px 8px rgba(var(--text-color-rgb), 0.08);
    }

    .toast-title {
      margin-bottom: 10px;
    }

    .toast-title,
    .toast-message,
    .toast-close-button {
      color: var(--text-color) !important;
    }

    .toast-progress {
      background-color: rgba(var(--text-color-rgb), 0.14);
    }

    &.attention,
    &.warning,
    &.success {
      &:before {
        display: block;
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-right-width: 5px;
        border-right-style: solid;
      }
    }

    &.attention {
      border-color: var(--attention-color);

      &:before {
        border-right-color: var(--attention-color);
      }

      .toast-progress {
        background-color: var(--attention-color);
      }
    }

    &.warning {
      border-color: var(--warn-color);

      &:before {
        border-right-color: var(--warn-color);
      }

      .toast-progress {
        background-color: var(--warn-color);
      }
    }

    &.success {
      border-color: var(--success-color);

      &:before {
        border-right-color: var(--success-color);
      }

      .toast-progress {
        background-color: var(--success-color);
      }
    }

    .toast-close-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 0;
      width: 40px;
      background: transparent;
      border-left: 1px solid rgba(var(--text-color-rgb), 0.08);
      text-shadow: none;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    @keyframes newNotification {
      0% {
        opacity: 0;
        transform: translateX(150px);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

.not-found {
  font-size: 1rem;
  opacity: 0.6;
}

.frow-container.full-width {
  max-width: 2200px;
}

@media (min-width: 1650px) {
  .frow .col-xl-1-8 {
    width: 12.5%;
  }
}

@media (min-width: 1650px) {
  .frow .col-xl-1-6 {
    width: calc(100% / 6);
  }
}

input,
textarea {
  border: 1px solid rgba(var(--text-color-rgb), 0.05);
  padding: 8px 10px;
  border-radius: 4px;
  margin-top: 5px;
  background: rgba(var(--text-color-rgb), 0.02);
  color: var(--text-color);

  &:hover {
    border-color: rgba(var(--text-color-rgb), 0.2);
  }

  &:focus {
    border-color: rgba(var(--text-color-rgb), 0.2);
  }
}

input:disabled,
textarea:disabled {
  opacity: 0.4;
  color: rgba(var(--text-color-rgb), 0.6);
  border: 1px solid rgba(var(--text-color-rgb), 0.05);

  &:hover {
    border: 1px solid rgba(var(--text-color-rgb), 0.05);
    cursor: not-allowed;
  }
}

.tabs {
  border-bottom: 1px solid rgba(var(--text-color-rgb), 0.08);
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -1rem;

  a.tab {
    position: relative;
    padding: 0.8rem 1.2rem 0.8rem 1.2rem;
    font-size: 0.95rem;
    opacity: 0.4;
    transition: opacity 0.12s ease-in;

    &.active:after,
    &:hover,
    &.active {
      opacity: 1;
    }

    &:after {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      bottom: -1px;
      left: 0;
      background: var(--text-color);
      transition: opacity 0.2s ease-in;
      opacity: 0;
    }

    &:hover {
      text-decoration: none;
    }

    @media (max-width: 991px) {
      padding: 0.7rem 1rem 0.7rem 1rem;
    }

    @media (max-width: 767px) {
      width: 100%;

      &.active:after {
        opacity: 0;
      }

      &.active {
        background: rgba(var(--text-color-rgb), 0.08);
        border-radius: 4px;
      }
    }
  }
}

body .ng-select.ng-select-single .ng-select-container {
  width: 200px;
  max-width: 200px;
  padding: 7px 20px 7px 0;
  height: 42px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  background: rgba(var(--text-color-rgb), 0.06);
  color: var(--text-color);
  cursor: pointer;
  font-family: inherit;
  font-size: 0.8rem;
  transition: all 150ms ease;
  margin: 0;
  line-height: 1rem;

  .ng-arrow-wrapper {
    display: none;
  }
}

.ng-clear-wrapper {
  margin-right: 4px;
}

.ng-select .ng-placeholder {
  opacity: 0.5;
  color: var(--text-color);
}

body .ng-select.ng-select-multiple .ng-select-container {
  padding: 7px 20px 7px 0;
  height: 42px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  background: rgba(var(--text-color-rgb), 0.06);
  color: var(--text-color);
  cursor: pointer;
  font-family: inherit;
  font-size: 0.8rem;
  transition: all 150ms ease;
  margin: 0;
  line-height: 1rem;

  .ng-arrow-wrapper {
    display: none;
  }

  .ng-placeholder {
    line-height: 30px;
    opacity: 0.5;
  }

  input {
    margin-top: 0;
  }

  .ng-value-container .ng-value {
    background-color: white;
    color: black;

    .ng-value-icon.left {
      border: none;

      &:hover {
        opacity: 0.5;
        background-color: white;
      }
    }
  }
}

// aria-busy loader

a[aria-busy='true'],
button[aria-busy='true'],
input[type='button'][aria-busy='true'],
input[type='reset'][aria-busy='true'],
input[type='submit'][aria-busy='true'] {
  pointer-events: none;
}

[aria-busy='true'] {
  .hidden-busy {
    display: none;
  }
}

[aria-busy='true']:not(input):not(select):not(textarea)::before {
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 0.1875em solid var(--text-color);
  border-radius: 1em;
  border-right-color: transparent;
  vertical-align: text-bottom;
  vertical-align: -0.125em;
  -webkit-animation: spinner 0.75s linear infinite;
  animation: spinner 0.75s linear infinite;
  content: '';
  opacity: 0.5;
}

button[aria-busy='true']:not(input):not(select):not(textarea)::before {
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 0.1875em solid white;
  border-radius: 1em;
  border-right-color: transparent;
  vertical-align: text-bottom;
  vertical-align: -0.125em;
  -webkit-animation: spinner 0.75s linear infinite;
  animation: spinner 0.75s linear infinite;
  content: '';
  opacity: 0.5;
}

[aria-busy='true']:not(input):not(select):not(textarea):not(:empty)::before {
  margin-right: 0.5rem;
  margin-left: 0;
  margin-inline-end: 0.5rem;
  margin-inline-start: 0;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

input[type='range'] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background: var(--text-color);
  cursor: pointer;
  margin-top: -3px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background: rgba(var(--text-color));
  cursor: pointer;
}

/* All the same stuff for IE */
input[type='range']::-ms-thumb {
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background: rgba(var(--text-color));
  cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
  background: rgba(var(--text-color-rgb), 0.15);
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: rgba(var(--text-color-rgb), 0.25);
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
  background: rgba(var(--text-color-rgb), 0.15);
}

input[type='range']::-ms-track {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: rgba(var(--text-color-rgb), 0.15);
}
input[type='range']:focus::-ms-fill-lower {
  background: rgba(var(--text-color-rgb), 0.15);
}
input[type='range']::-ms-fill-upper {
  background: rgba(var(--text-color-rgb), 0.15);
}
input[type='range']:focus::-ms-fill-upper {
  background: rgba(var(--text-color-rgb), 0.15);
}

.custom-pagination {
  a.navigate {
    border: 1px solid rgba(var(--text-color-rgb), 0.1);
    border-radius: 50%;
    width: 28px;
    height: 28px;
    background: var(--area-bg);
    transition: opacity 0.1s ease-in-out;

    span {
      vertical-align: middle;
      font-size: 1rem;
    }

    &:hover {
      opacity: 0.5;
      text-decoration: none;
    }
  }

  .pagination-previous {
    span {
      margin-right: 1.5px;
    }
  }

  .pagination-next {
    span {
      margin-left: 1.5px;
    }
  }

  .page-of {
    font-size: 0.9rem;
    line-height: 28px;
    opacity: 0.75;
  }

  .current-page {
    font-size: 0.9rem;
    line-height: 26px;
    height: 28px;
    border-radius: 4px;
    min-width: 28px;
    display: inline-block;
    margin: 0 2px;
    padding: 0 4px;
    background: rgba(var(--text-color-rgb), 0.05);
    border: 1px solid transparent;
  }

  .pagination-pages {
    a {
      font-size: 0.9rem;
      border: 1px solid transparent;
      height: 28px;
      border-radius: 4px;
      min-width: 28px;
      line-height: 25px;
      transition: opacity 0.1s ease-in-out;
      display: inline-block;
      margin: 0 2px;
      border-color: transparent;

      span {
        vertical-align: middle;
      }

      &:hover {
        opacity: 0.5;
        text-decoration: none;
      }
    }
  }
}

.ng-dropdown-panel .scrollable-content {
  position: absolute !important;
}

body {
  input.toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 42px;
    height: 22px;
    display: inline-block;
    position: relative;
    border-radius: 22px;
    overflow: hidden;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: rgba(var(--text-color-rgb), 0.2);
    transition: background-color ease-in-out 0.2s;

    &:hover,
    &:focus {
      transform: none;
    }
  }

  input.toggle:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    width: 18px;
    height: 18px;
    background: #fff;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    text-transform: uppercase;
    font-weight: bold;
    text-indent: -22px;
    word-spacing: 37px;
    color: #fff;
    white-space: nowrap;
    box-shadow: 0 1px 2px rgba(var(--text-color-rgb), 0.2);
    transition: all 0.2s ease-in-out;
  }

  input.toggle:checked {
    background-color: var(--main-color);
    background-image: none;
  }

  input.toggle:checked:before {
    left: 22px;
  }
}

// Tippy
.tippy-box {
  color: var(--text-color);
  background-color: var(--area-bg);
  border: 1px solid rgba(var(--text-color-rgb), 0.1);
  box-shadow: 4px 4px 8px rgba(var(--text-color-rgb), 0.04);
}
.tippy-box[data-placement^='top'] > .tippy-arrow:before {
  border-top-color: var(--area-bg);
}
.tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
  border-bottom-color: var(--area-bg);
}
.tippy-box[data-placement^='left'] > .tippy-arrow:before {
  border-left-color: var(--area-bg);
}
.tippy-box[data-placement^='right'] > .tippy-arrow:before {
  border-right-color: var(--area-bg);
}
.tippy-box > .tippy-backdrop {
  background-color: var(--area-bg);
}
.tippy-box > .tippy-svg-arrow {
  fill: var(--area-bg);
}
